import(/* webpackMode: "eager", webpackExports: ["AdminProviders"] */ "/app/apps/web/src/app/(Admin)/AdminProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdminPortalProviders"] */ "/app/apps/web/src/app/(Admin)/portal/(AdminPortal)/AdminPortalProviders.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/pages/_app.css");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useComputedColorScheme"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/use-mantine-color-scheme/use-computed-color-scheme.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMantineColorScheme"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/use-mantine-color-scheme/use-mantine-color-scheme.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@xyflow/react/dist/style.css");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/styles.css");
